import * as React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import {} from "../components/sobhaVilla1Comp/heroArea.module.css";
import NavHeader from "../components/NavHeader";
import HeroArea from "../components/newBeachFront/heroArea";
// import Connectivity from "../components/newBeachFront/connectivities";
// import Eminity from "../components/newBeachFront/eminity";
// import Plan from "../components/newBeachFront/plan";
import Footer from "../components/newBeachFront/footer";
// import StickyForm from "../components/newBeachFront/stickyForm";
// import BeachHomeImage from "../images/newbeach/newbeachfrontimg.jpg";
// import PrivatePool from '../images/the-crest.png'
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/App/SEO";
import { siteUrl } from "../components/_utils";
import { useEffect, useState } from "react";
import { getCountry } from "../action";
import { useScrollTracker } from "react-scroll-tracker";
import ReactGA from "react-ga";
// import { contentDiv } from "../styles/sobha.module.css";
// import StickyBox from "react-sticky-box";
// import ModalPage from "../components/modalPage1";
import BeachDetails from "../components/newBeachFront/beachDetail";
import GetPlans from "../components/newBeachFront/getPlans";
import SecondDetails from "../components/newBeachFront/secondDetails";
import DisplayPics from "../components/newBeachFront/displayPics";
import Properties from "../components/newBeachFront/properties";
import EmaarIntro from "../components/newBeachFront/emaarIntro";
import BigParallaxIage from "../components/newBeachFront/bigParallaxImg";
const shortNumber = require("short-number");
let dollarAmount = 265450;
const EmaarBeachFront = ({ data }) => {
  const [countryISO, setCountryISO] = useState("ae");
  const [short, setShort] = useState(shortNumber(dollarAmount));
  const [symbol, setSymbol] = useState("$");
  const [downloadType, setDownloadFormType] = useState(null);
  useEffect(() => {
    getCountry({ amount: dollarAmount }).then((data) => {
      if (data.short) {
        setShort(data.short);
      }
      if (data.symbol) {
        setSymbol(data.symbol);
      }
      if (data.iso) {
        setCountryISO(data.iso.toLowerCase());
      }
    });
  }, []);
  if (typeof window !== "undefined") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useScrollTracker([10, 15, 25, 50, 75, 100], ({ scrollY }) => {
      ReactGA.event({
        category: "scroll",
        action: scrollY,
      });
    });
  }
  const logoComp = (
    <>
    <StaticImage
      className="hidden md:block"
      placeholder="tracedSVG"
      width={100}
      height={100}
      src={"../images/newbeach/Emaarlogo.png"}
      alt={"Emaar logo"}
    />
    <StaticImage
      className="block md:hidden"
      placeholder="tracedSVG"
      width={55}
      height={55}
      src={"../images/newbeach/Emaarlogo.png"}
      alt={"Emaar logo"}
    />
    </>
    
    // <h1 className="text-xl font-semibold md:text-3xl">EMAAR</h1>
  );

  const titleParams = {
    title: `Emaar Beach Front | EMAAR | ${siteUrl}`,
    ogTitle: `Emaar Beach Front | EMAAR | ${siteUrl}`,
    description:
      "Where Escaping is staying | Enjoy the perks of living by a crystal lagoon in central Dubai, Caribbean-style crystal lagoon facing residences",
    ogDescription:
      "Where Escaping is staying | Enjoy the perks of living by a crystal lagoon in central Dubai, Caribbean-style crystal lagoon facing residences",
    keywords: "5 YEAR PAYMENT PLAN",
    // secureImageUrl: `${PrivatePool}`,
    // ogImage: `${PrivatePool}`,
    ogImageAlt: "Emaar Beach Front ",
    orUrl: `${siteUrl}emaar-beach-front/`,
    pageLink: `${siteUrl}emaar-beach-front/`,
    canonicalRef: `${siteUrl}emaar-beach-front/`,
  };
  return (
    <SimpleReactLightbox>
      <Seo data={titleParams} />
      <NavHeader logoComp={logoComp} />
      <HeroArea
        countryISO={countryISO}
        onFormOpen={(v) => {
          setDownloadFormType(v);
        }}
        short={short}
        symbol={symbol}
      />

      <BeachDetails />
      <button className="detailsBtn">GET MORE DETAILS</button>
      <StaticImage
        height={500}
        className="w-full h-1/3 bg-fixed"
        src="../images/newbeach/droneView.webp"
        alt="Drone View Map of Plot"
      />
      <GetPlans />
      <BigParallaxIage />
      <SecondDetails />
      <button className="detailsBtn">GET MORE DETAILS</button>
      <DisplayPics />
      <Properties />
      <EmaarIntro />

      <Footer />
    </SimpleReactLightbox>
  );
};
export default EmaarBeachFront;
