import React from "react";
const BigParallaxIage = () => {
  return (
    <>
      <div className="bigParallaxImg">
        <div className="parallaxText">
          <span className="font-semibold">Waterfront Homes</span> with Beach
          Access on a <br />
          <span className="font-semibold">Private Island</span> in Dubai
        </div>
      </div>
    </>
  );
};
export default BigParallaxIage;
