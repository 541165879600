import { StaticImage } from "gatsby-plugin-image";
import React from "react";
const SecondDetails = () => {
  return (
    <>
      <div className="secondDetails">
        <div className="secDetailsGrid">
          <div className="secondDetailGrid">
            <div className="secDetailsIcon">
              <StaticImage
                src="../../images/newbeach/icons/umbrella.webp"
                alt="umbrella logo"
              />
            </div>
            <div className="secDetailsText">
              <h1>1.5 km</h1>
              <p>Pristine Beach</p>
            </div>
          </div>
          <div className="secondDetailGrid">
            <div className="secDetailsIcon">
              <StaticImage
                src="../../images/newbeach/icons/building.webp"
                alt="building logo"
              />
            </div>
            <div className="secDetailsText">
              <h1>27</h1>
              <p>Residential Towers</p>
            </div>
          </div>
          <div className="secondDetailGrid">
            <div className="secDetailsIcon">
              <StaticImage
                src="../../images/newbeach/icons/arrow.webp"
                alt="arrow logo"
              />
            </div>
            <div className="secDetailsText">
              <h1>Easy Access</h1>
              <p>To Sheikh Zayed Road</p>
            </div>
          </div>
          <div className="secondDetailGrid">
            <div className="secDetailsIcon">
              <StaticImage
                src="../../images/newbeach/icons/mall.webp"
                alt="mall logo"
              />
            </div>
            <div className="secDetailsText">
              <h1>13,000 sqm</h1>
              <p>Retail Mall</p>
            </div>
          </div>
          <div className="secondDetailGrid">
            <div className="secDetailsIcon">
              <StaticImage
                src="../../images/newbeach/icons/hook.webp"
                alt="sea hook logo"
              />
            </div>
            <div className="secDetailsText">
              <h1>Dubai Marina</h1>
              <p>At Your Doorstep</p>
            </div>
          </div>
          <div className="secondDetailGrid">
            <div className="secDetailsIcon">
              <StaticImage
                src="../../images/newbeach/icons/sea-waves.webp"
                alt="sea waves logo"
              />
            </div>
            <div className="secDetailsText">
              <h1>Sea Views</h1>
              <p>Apartments Overlooking the Sea</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondDetails;
