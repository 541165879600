import { StaticImage } from "gatsby-plugin-image";
import React from "react";
const Properties = () => {
  return (
    <>
      <div className="properties text-center ">
        <h1 className="font-bold text-4xl p-9">PROPERTIES</h1>
        <div className="propertiesGrid">
          <div>
            <StaticImage
              src="../../images/newbeach/Properties/Beach-Vista.webp"
              alt="Beach Vista"
            />
            <h3 className="propertyTitle font-bold text-2xl px-1 py-3 pt-7">
              Beach Vista
            </h3>
            <p className="propertyDescription py-2 pb-10">
              50I50 3 Years Post Handover Payment Plan + 50% DLD Waiver + 2
              Years SF Waiver
            </p>
            <button className="availBtn">Check Availability</button>
          </div>
          <div>
            <StaticImage
              src="../../images/newbeach/Properties/Sunrise-Bay.webp"
              alt="Sunrise Bay"
            />
            <h3 className="propertyTitle font-bold text-2xl px-1 py-3 pt-7">
              Sunrise Bay
            </h3>
            <p className="propertyDescription py-2 pb-10">
              50I50 3 Years Post Handover Payment Plan + 50% DLD Waiver + 2
              Years SF Waiver
            </p>
            <button className="availBtn">Check Availability</button>
          </div>
          <div>
            <StaticImage
              width={360}
              height={396}
              src="../../images/newbeach/Properties/building-model.jpg"
              alt="Building Model"
            />
            <h3 className="propertyTitle font-bold text-2xl px-1 py-3 pt-7">
              Marina Vista
            </h3>
            <p className="propertyDescription py-2 pb-10">
              60|40 2 Years Post Handover Payment Plan + (50% DLD Waiver OR 3
              Years Ease by Emaar)
            </p>
            <button className="availBtn">Check Availability</button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Properties;
