import { StaticImage } from "gatsby-plugin-image";
import React from "react";
const GetPlans = () => {
  return (
    <>
      <div className="plan-box text-center">
        <h1 className="pb-6">PERFECTLY CONNECTED</h1>
        <div className="semi-border"></div>
        <p className="pt-6">
          The sweet life starts with a tropical breeze. And with direct access
          to Dubai Marina and a swift connection to Sheikh Zayed Road, your
          every day can only get sweeter.
        </p>
      </div>
      <div className="text-center plan-view-img">
        <div className="plan-view">
          <div className=" m-10 mobile-view-map">
            <StaticImage
              className="mobile-map-view-display"
              src="../../images/newbeach/mapview.webp"
              alt="map view of location"
            />
          </div>
          <div className="plan-details">
            <h1>05</h1>
            <p>minutes</p>
            <p>Dubai Marina</p>
          </div>
          <div className="plan-details">
            <h1>05</h1>
            <p>minutes</p>
            <p>Sheikh Zayed Road</p>
          </div>
          <div className="plan-details">
            <h1>25</h1>
            <p>minutes</p>
            <p>Downtown Dubai</p>
          </div>
          <div className="plan-details">
            <h1>25</h1>
            <p>minutes</p>
            <p>Al Maktoum International Airport</p>
          </div>
        </div>
        <div className="view-map">
          <StaticImage
            className="view-map-origin"
            src="../../images/newbeach/mapview.webp"
            alt="map view of location"
          />
        </div>
      </div>
      <div className="priceBtnBg">
        <button className="PriceBtn p-4 font-bold flex justify-center">
          GET PRICE & PAYMENT PLAN
        </button>
      </div>
    </>
  );
};
export default GetPlans;
