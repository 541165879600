import { headerSection, mainSection, formBox } from "./heroArea.module.css";
import FormLayout from "./form";
import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { loaderText } from "../fiveJBR/form.module.css";
// import { ImSpinner } from "react-icons/im";
// import ModalPage from "../modalPage1";
import { contentDiv, formBoxAbs } from "../../styles/sobha.module.css";
// import Connectivity from "./connectivities";
// import Eminity from "./eminity";
// import StickyForm from "./stickyForm";
import StickyBox from "react-sticky-box";
import "./style.css";

const HeroArea = ({ countryISO, short, symbol, downloadType, onFormOpen }) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <>
      <div className={mainSection}>
        <section
          className={`${headerSection} text-center bg-center md:bg-center font-lato grid-cols-12`}
        >
          <div className={"block md:hidden"}>
            <StaticImage
              width={1900}
              height={1859}
              className="beachImgMobile"
              src={"../../images/newbeach/newbeachfrontimg.jpg"}
              alt={"The Beach View"}
            />
          </div>

          <div className={"hidden w-full h-screen md:block"}>
            <StaticImage
              className={"w-full h-screen"}
              src={"../../images/newbeach/newbeachfrontimg.jpg"}
              alt={"The Beach View"}
            />
          </div>

          <div className={"md:hidden pt-4 px-4 block bg-gray-100  pb-2"}>
            <div className={`bg-white py-3  `}>
              <FormLayout countryISO={countryISO} mandatoryitems={true} />
            </div>

            {/* <div className={"mt-2 text-center px-6"}>
              <button
                onClick={() => {
                  onFormOpen("brochure");
                  document.getElementById("downloadbrochure").style.display =
                    "block";
                }}
                disabled={loading}
                style={{ flex: 1, alignContent: "center" }}
                className={
                  "w-10/12 rounded  text-lg  bg-primary-darkGold py-3 px-4 text-white"
                }
                type="submit"
              >
                <span className={loaderText}>
                  {loading && <ImSpinner className={"icon-spin "} />}
                </span>
                <span>Download Brochure</span>
              </button>
            </div> */}
          </div>
        </section>
        <div className={`${formBoxAbs} hidden md:block`}>
          <div className={`${contentDiv}`}>
            <div className="grid grid-cols-12 gap-1">
              <div className="col-span-8 lg:col-span-8 lg:pr-16"></div>

              <div className="col-span-4 hidden lg:block">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <div
                    className={`hidden md:block grid-cols-4 bg-white p-1 mt-8 mx-auto  py-10 ${formBox}`}
                  >
                    <FormLayout countryISO={countryISO} />
                  </div>
                </StickyBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroArea;
