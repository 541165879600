import { StaticImage } from "gatsby-plugin-image";
import React from "react";
// import ImageX1 from "../../images/newbeach/Emaar-BeachFrontx1.webp";
const BeachDetails = () => {
  return (
    <>
      <div className="detailArea overflow-hidden">
        <div className="details">
          <h3>PRIVATE BEACH LIVING</h3>
          <h1>EMAAR BEACHFRONT</h1>
          <p>
            Where the sea greets you every morning, and pristine white sand
            welcomes you home—on an island with a statement address in Dubai.
            Awaken your senses with every breath, as you redefine your premium
            lifestyle, overlooking the majestic Arabian Sea at Emaar Beachfront.
          </p>
        </div>
        <div className="detail-sideImg">
          <StaticImage
            className="ImageX1"
            width={460}
            height={448}
            src="../../images/newbeach/Emaar-BeachFrontx1.webp"
            alt="Emaar Beach View"
          />
          <div className="ImageX2">

          <StaticImage
            width={364}
            height={224}
            src="../../images/newbeach/Emaar-BeachFront-2x2.webp"
            alt="Emaar Beach View"
          />
          </div>

        </div>
      </div>
    </>
  );
};

export default BeachDetails;
