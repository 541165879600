import React from "react";
import "./style.css";

const HeroText = () => {
  return (
    <>
      <div className="textBg">
        <h1>
          EMAAR <br /> BEACHFRONT
        </h1>

        <h3>
          Launching New Exclusive Tower at Beachfront with 1.5 KM private beach
          exclusive for residents only
        </h3>
      </div>
    </>
  );
};
export default HeroText;
