import React from "react";
const EmaarIntro = () => {
  return (
    <>
      <div className="emaar-intro text-center">
        <h1 className=" font-bold">EMAAR</h1>
        <p>
          An undisputed leader in the global real estate market, EMAAR has grown
          over the years to become the largest real estate developer outside of
          mainland China. You will be investing with one of the most-loved
          brands in the world.
        </p>
      </div>
    </>
  );
};

export default EmaarIntro;
