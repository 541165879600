import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
const DisplayPics = () => {
  return (
    <>
      <div className="displayPics mt-16">
        <SRLWrapper>

        <StaticImage
          className="displayPic"
          src="../../images/newbeach/display1.webp"
          alt="Display Pics First"
        />

        <StaticImage
          className="displayPic"
          src="../../images/newbeach/display2.webp"
          alt="Display Pics First"
        />
        <StaticImage
          className="displayPic"
          src="../../images/newbeach/display3.webp"
          alt="Display Pics First"
        />
        <StaticImage
          className="displayPic"
          src="../../images/newbeach/display4.webp"
          alt="Display Pics First"
        />
        </SRLWrapper>

      </div>
    </>
  );
};
export default DisplayPics;
