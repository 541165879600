// extracted by mini-css-extract-plugin
export var mainSection = "heroArea-module--mainSection--2KK4w";
export var headerSection = "heroArea-module--headerSection--3CwFW";
export var topSection = "heroArea-module--topSection--1cEKU";
export var bottomUnderLine = "heroArea-module--bottomUnderLine--1y1pP";
export var footerList = "heroArea-module--footerList--edjzw";
export var heroText = "heroArea-module--heroText--2Tggt";
export var crestList = "heroArea-module--crestList--18_9V";
export var sobhaHeading = "heroArea-module--sobhaHeading--IoMej";
export var sliderFooterContainer = "heroArea-module--sliderFooterContainer--2h0t-";
export var sliderFooter = "heroArea-module--sliderFooter--2uHKm";
export var videoContainer = "heroArea-module--videoContainer--LC8Vc";
export var gridBox = "heroArea-module--gridBox--1YKPQ";
export var firstBlock = "heroArea-module--firstBlock--1FDlu";
export var gridBox2 = "heroArea-module--gridBox2--3qZfE";
export var area = "heroArea-module--area--253if";
export var price = "heroArea-module--price--3WmNF";
export var downloadBtn = "heroArea-module--downloadBtn--2jrkJ";
export var firstGrid = "heroArea-module--firstGrid--1djiy";
export var gridText = "heroArea-module--gridText--1HLMi";
export var minTextClass = "heroArea-module--minTextClass--3hrvH";
export var addressBlock = "heroArea-module--addressBlock--3Kapu";
export var formBox = "heroArea-module--formBox--1zh57";